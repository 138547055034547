<template>
  <div id="app">
    <v-app id="inspire">
      <div class="my-header">
        <div class="my-header-item my-left">
          <img src="https://bizweb.dktcdn.net/100/415/690/themes/804206/assets/logo.png?1670472192221" alt="Vietmap"
            height="18" />
        </div>
        <div class="my-header-item my-trans"></div>
        <div class="my-header-item my-right">
          {{ email }}
          <v-btn x-small color="primary" text>
            <v-icon small dark @click="logout"> mdi-logout </v-icon>
          </v-btn>
        </div>
      </div>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import json from "../public/config.json";

export default {
  data: () => ({
    appName: "Vietmap Internal App",
    email: "",
    config: json,
  }),
  updated() {
    let routeName = this.$route.name;
    let appName = localStorage.getItem("AppName");
    this.appName =
      routeName != "home"
        ? appName != undefined
          ? appName
          : "Vietmap Internal App"
        : "Vietmap Internal App";

    if (routeName != "microsoftcallback" && routeName != "redirect") {
      let email = localStorage.getItem("Email");
      if (email == null) {
        location.href =
          "https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Fcortex.vietmap.io%2F%23%2Fmicrosoftcallback"; // PROD
        //"https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Ftesting-cortex.vietmap.io%2F%23%2Fmicrosoftcallback"; // TESTING
        //"https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2Flocalhost%3A8080%2F%23%2Fmicrosoftcallback";
        //"https://sso.vietmap.vn/#/login?force=true&appId=process.maker&returnUrl=http%3A%2F%2F192.168.34.35%3A8080%2F%23%2Fmicrosoftcallback";
      } else {
        this.email = email;
      }
    }
  },
  methods: {
    goToAppCategory() {
      location.href = "#/";
    },
    clearCached() {
      console.log("clearCached");
      window.open(
        "https://jet.vietmap.vn/public/pcz1e7am7koqxfh5rilq5thsyix8192j"
      );
    },
    logout() {
      localStorage.removeItem("Email");
      location.href = "https://sso.vietmap.vn/#/login?force=true";
    },
  },
};
</script>
<style>
.v-list-item {
  cursor: pointer !important;
}

.v-application .primary {
  background-color: #3b82f6 !important;
  border-color: #3b82f6 !important;
}

.my-header {
  position: fixed;
  width: 100%;
  z-index: 999 !important;
}

.my-header-item {
  float: left;
  width: 33.33%;
  padding: 11px;
}

.my-trans {
  background: transparent !important;
}

.my-left {
  background: #ffffff;
}

.my-right {
  text-align: right;
  background: #ffffff;
  font-weight: bold;
  font-size: 10pt;
}

.v-btn--fab.v-size--x-small {
  height: 24px !important;
}

body {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
</style>